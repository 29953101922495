<template>
  <div id="link-our-schedule-section" class="schedule-section">
    <div class="darker-overlay">
      <div class="text-center container">
        <div class="row">
          <div class="col">
            <p class="font-1 color-5 our-schedule-title">{{ title }}</p>
            <p class="font-2 color-3 our-schedule-subtitle">{{ subtitle }}</p>
          </div>
        </div>
        <div class="row">
          <div
            v-for="schedule in schedules"
            :key="schedule"
            class="col-md-6 a-schedule mt-4"
          >
            <p class="font-3 color-2 our-schedule-day">{{ schedule.days }}</p>
            <div class="font-2 color-5 our-schedule-time">
              <span v-for="time in schedule.times" :key="time">
                {{ time }}
              </span>
            </div>
          </div>
        </div>
        <div class="row our-schedule-call">
          <div class="col">
            <a class="nounderline" href="tel:+34-932-31-77-16">
              <h2 class="tel-link font-3 color-3">+34 932 31 77 16</h2>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurSchedule",
  computed: {
    schedules: function () {
      return this.getScheduleData().schedules;
    },
    title: function () {
      return this.getScheduleData().title;
    },
    subtitle: function () {
      return this.getScheduleData().subtitle;
    },
    callNowText: function () {
      return this.getScheduleData().callNow;
    },
  },
  methods: {
    getScheduleData: function () {
      return this.$store.getters.currentData.ourSchedule;
    },
  },
};
</script>

<style scoped lang="scss">
.schedule-section {
  background-image: url("../../assets/img/open-door.jpeg");
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
}

.our-schedule-title {
  font-size: 35px;
  @media screen and (min-width: 960px) {
    padding-bottom: 45px;
  }
}

.our-schedule-call {
  padding-top: 20px;
  @media screen and (min-width: 960px) {
    padding-top: 20px;
    margin-top: 30px;
  }
}

.our-schedule-subtitle {
  font-size: 23px;
  @media screen and (min-width: 960px) {
    // padding-bottom: 25px;
    font-size: 35px;
  }
}

.our-schedule-day {
  font-size: 20px;
  @media screen and (min-width: 960px) {
    font-size: 25px;
  }
}

.our-schedule-time {
  font-size: 23px;
  font-weight: bold;
  letter-spacing: 3px;
}

.a-schedule {
  border-color: goldenrod;
  border-top: 1px solid 50%;
}

.darker-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  padding-top: 30px;
  padding-bottom: 30px;
  @media screen and (min-width: 960px) {
    padding-top: 55px;
    padding-bottom: 55px;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}

.tel-link {
  animation: pulse 0.5s linear infinite;
}

.tel-link:hover {
  color: #fb9a23;
}

.nounderline {
  text-decoration: none !important;
}
</style>
