<template>
  <div id="link-contact-us-section">
    <div class="row pt-4 pb-2">
      <div class="col">
        <p class="module-title color-4 text-center">{{ htaTitle }}</p>
        <p class="module-subtitle color-1 text-uppercase text-center">
          {{ htaSubtitle }}
        </p>
      </div>
    </div>
    <div class="container hta-container">
      <div class="row">
        <div
          v-for="contactCard in contactCards"
          :key="contactCard.icon"
          class="col-lg-4 col-md-12"
        >
          <div class="row justify-content-center h-100">
            <div class="col-lg-12 col-md-6">
              <ContactCard v-bind:contactInfo="contactCard" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactCard from "./ContactCard";

export default {
  name: "ContactUs",
  components: {
    ContactCard,
  },
  methods: {
    getHtaData: function() {
      return this.$store.getters.currentData.howToFindUs;
    },
  },
  computed: {
    htaTitle: function() {
      return this.getHtaData().title;
    },
    htaSubtitle: function() {
      return this.getHtaData().subtitle;
    },
  },
  data: function() {
    return {
      contactCards: [
        {
          icon: "fas fa-phone",
          descriptions: ["+34 932 31 77 16"],
          anchorLink: "tel:+34-932-31-77-16"
        },
        {
          icon: "fas fa-map-marker-alt",
          descriptions: [
            "Carrer del Consell de Cent, 424",
            "Barcelona, Sapin",
            "08013",
          ],
          anchorLink:
            "https://www.google.com/maps/place/Casa+Muyo,+C.+del+Consell+de+Cent,+424,+08013+Barcelona,+B/@41.397765,2.1746616,18z/data=!4m6!1m3!3m2!1s0x12a4a312da96addf:0x57a680f35b375321!2sCasa+Muyo,+C.+del+Consell+de+Cent,+424,+08013+Barcelona,+B!3m1!1s0x12a4a312da96addf:0x57a680f35b375321",
        },
        {
          icon: "fas fa-envelope",
          descriptions: ["hermanos@restaurantecasamuyo.com"],
          anchorLink: "mailto:hermanos@restaurantecasamuyo.com",
        },
      ],
    };
  },
};
</script>

<style scoped>
.hta-container {
}

#link-contact-us-section {
  margin-top: 10px;
  margin-bottom: 35px;
}
</style>
