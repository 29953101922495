<template>
  <landing />
</template>

<script>
import Landing from "./components/Landing";

export default {
  name: "App",
  components: {
    Landing,
  },
};
</script>

<style>
#app {
}
html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  background-color: #f9ead0;
}
</style>
