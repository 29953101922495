<template>
    <div class="m-4" id="accordionGroup">
        <div class="card-header card-header-aux font-2 color-4 mb-2" id="headingOne">
          <div class="collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" >
          {{ textMinTwo }}
          </div>
        </div>
        <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionGroup" >
          <div class="card-body">
           <MenuForTwo />
          </div>
        </div>
        <div class="card-header card-header-aux font-2 color-4" id="headingTwo">
          <div class="collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" >
          {{ textMinFour }}
          </div>
        </div>
        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionGroup" >
          <div class="card-body">
           <MenuForFour />
          </div>
        </div>
      </div>
</template>

<script>
import MenuForFour from "./MenuForFour";
import MenuForTwo from "./MenuForTwo";
export default {
    name: "MenuForGroup",
    components: {
        MenuForFour,
        MenuForTwo
    },
    computed: {
        textMinTwo: function() {
            return this.getGroupMenuData().textMinTwo;
        },
        textMinFour: function() {
            return this.getGroupMenuData().textMinFour;
        },
    },
    methods: {
        getGroupMenuData: function() {
            return this.$store.getters.currentData.groupMenuSection;
        }
    }
}
</script>

<style scoped>

.card-header-aux {
  padding-top: 16px;
  padding-bottom: 16px;
  cursor: pointer;
  font-size: 23px;
  letter-spacing: 1px;
}

.card-header-aux:hover {
  color: #ebf38b;
  background-color: #a42e0e;
  opacity: 0.8;
}
</style>