<template>
  <div class="row history-banner">
    <div
      v-for="image in images"
      v-bind:key="image"
      class="col history-image"
      :style="{ backgroundImage: 'url(' + require('@/assets/' + image) + ')' }"
    >
      <div class="history-overlay"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HistoryBanner",
  data: function() {
    return {
      images: [
        "img/history_01.jpeg",
        "img/history_02.jpeg",
        "img/history_03.jpeg",
      ],
    };
  },
};
</script>

<style scoped>
.history-banner {
  height: 225px;
}

.history-image {
  height: 100%;
  background-size: cover;
  background-position: center;
  padding: 0px;
}

.history-overlay {
  height: 100%;
  width: 100%;
  background: rgba(110, 66, 22, 0.4);
  margin: 0px;
}

@media only screen and (max-width: 500px) {
  .history-banner {
    height: 125px;
  }
}
</style>
