<template>
  <div class="dining-section h-zigzag-color-4">
    <div class="container text-center">
      <h2 class="font-1 color-5 dining-section--title">
        {{ announcement }}
      </h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivateDining",
  computed: {
    announcement: function () {
      return this.getDiningSection().content;
    },
  },
  methods: {
    getDiningSection: function () {
      return this.$store.getters.currentData.diningSection;
    },
  },
};
</script>

<style lang="scss">
@keyframes pulse {
  0% {
    transform: scale(0.99);
  }
  50% {
    transform: scale(1.01);
  }
  100% {
    transform: scale(0.99);
  }
}

.dining-section {
  padding-top: 15px;
  padding-bottom: 25px;
  text-shadow: 1px 1px 2px #000000;
  &--title {
    font-size: 1.2rem;
  }

  @media screen and (min-width: 960px) {
    padding-top: 25px;
    padding-bottom: 30px;
    &--title {
      font-size: 1.4rem;
    }
  }
}

.dining-section h2 {
  animation: pulse 1s linear infinite;
  letter-spacing: 2px;
}
</style>
