<template>
  <footer>
    <div class="bottom-section">
      <div class="row find-us-section bcolor-4">
        <div class="col">
          <h1 class="font-1 color-2">{{ findUsText }}</h1>
          <div class="row justify-content-center social-media-logos-bar">
            <a
              target="_blank"
              class="social-media-logo-link"
              v-for="socialMedia in socialMedias"
              :href="socialMedia.link"
              :key="socialMedia.link"
            >
              <img
                class="social-media-logo"
                :src="require(`../../assets/img/${socialMedia.path}`)"
              />
            </a>
          </div>
          <div class="color-5 font-3 lower-contact">
            <a class="color-5" href="tel:+34-932-31-77-16">+34932317716</a> .
            <a
              class="color-5"
              href="https://www.google.com/maps/place/Casa+Muyo,+C.+del+Consell+de+Cent,+424,+08013+Barcelona,+B/@41.397765,2.1746616,18z/data=!4m6!1m3!3m2!1s0x12a4a312da96addf:0x57a680f35b375321!2sCasa+Muyo,+C.+del+Consell+de+Cent,+424,+08013+Barcelona,+B!3m1!1s0x12a4a312da96addf:0x57a680f35b375321"
              >Carrer del Consell de Cent, 424, Barcelona</a
            >
            .
            <a class="color-5" href="mailto:hermanos@restaurantecasamuyo.com"
              >hermanos@restaurantecasamuyo.com
            </a>
          </div>
        </div>
      </div>
      <div class="row copyright-section">
        <div class="col">
          <p>
            <span class="font-2 color-2 copyright-name">
              (C) Restaurante Casamuyo .
            </span>
            <span class="font-3 color-5 copyright-text">
              {{ copyrightText }}.</span
            >
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data: function () {
    return {
      socialMedias: [
        {
          link: "https://www.facebook.com/restaurantecasamuyo/",
          path: "social-media-logos/facebook_64.png",
        },
        {
          link: "https://www.instagram.com/restaurantecasamuyo/",
          path: "social-media-logos/instagram_64.png",
        },
        {
          link: "https://wa.me/+34631810727",
          path: "social-media-logos/whatsapp_64.png",
        },
      ],
    };
  },
  computed: {
    copyrightText: function () {
      return this.getFooterData().copyrightText;
    },
    findUsText: function () {
      return this.getFooterData().findUs;
    },
  },
  methods: {
    getFooterData: function () {
      return this.$store.getters.currentData.footer;
    },
  },
};
</script>

<style lang="scss">
.copyright-section {
  font-size: 14px;
}

.find-us-section {
  padding-top: 30px;
  padding-bottom: 30px;
}

footer {
  text-align: center;
}

.copyright-section {
  padding-top: 18px;
  background-color: #c4532d;
}

.copyright-name {
  font-size: 18px;
}

.copyright-text {
  letter-spacing: 1px;
  font-size: 15px;
}

.social-media-logos-bar {
  padding-top: 10px;
  padding-bottom: 20px;
  @media screen and (min-width: 960px) {
    padding-top: 20px;
    margin-bottom: 20px;
  }
}

.social-media-logo {
  height: 25px;
  width: 25px;

  @media screen and (min-width: 960px) {
    height: 32px;
    width: 32px;
  }
}

.social-media-logo-link {
  margin-left: 10px;
  margin-right: 10px;
  height: 32px;
  width: 32px;
  transition: transform 0.25s ease;
}

.social-media-logo-link:hover {
  transform: translateY(-11px) scale(1.05);
}

.lower-contact {
  font-size: 13px;
  padding-right: 10px;
  padding-left: 10px;
}
</style>
