<template>
  <div id="the-team-section">
    <div class="container">
      <div>
        <p class="font-4 color-4 module-title text-center">
          {{ sectionTitle }}
        </p>
      </div>
      <div class="row text-center team-row">
        <div class="col team-member">
          <img class="team-image" src="../../assets/img/team_01.jpg" />
        </div>
        <div class="col team-member">
          <img class="team-image" src="../../assets/img/team_02.jpg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheTeam",
  computed: {
    sectionTitle: function() {
      return this.$store.getters.currentData.ourTeamSection.title;
    },
  },
};
</script>

<style scoped>
div#the-team-section {
  margin-top: 20px;
}

.team-member {
  padding: 35px;
}

img.team-image {
  width: 250px;
  height: 250px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}
</style>
