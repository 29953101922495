<template>
  <div id="link-menu-section" class="group-menu-section">
    <div class="text-center mb-4 menu-header-section">
      <div class="menu-header-overlay">
        <p class="module-title color-3">{{ title }}</p>
        <p class="module-subtitle color-2 text-uppercase">{{ subtitle }}</p>
        <a class="see-all-link font-3 color-3 nounderline" target="_blank" href="/casamuyo-menu-qr.pdf">{{ seeAllMenuText }} <i class="fas fa-book-open"></i></a><br>
        <a class="see-all-link font-3 color-3 nounderline" target="_blank" :href="require(`../../assets/pdf/carta-vino.pdf`)">{{ seeWineMenuText }} <i class="fas fa-wine-glass-alt"></i></a>
      </div>
    </div>
    <div class="container">
        <h2 class="color-4"> Menus : </h2><br>
    </div>


    <div id="accordion" class="container">
     <div class="card">
        <div class="card-header card-header-aux font-2 color-4" id="headingThree">
          <div class="collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" >
          {{ textMenuTapas }}
          </div>
        </div>
        <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion" >
         <MenuItems v-bind:items="tapas" />
        </div>
      </div>

     <div class="card">
        <div class="card-header card-header-aux font-2 color-4" id="headingSurtidos">
          <div class="collapsed" data-toggle="collapse" data-target="#collapseSurtidos" aria-expanded="false" aria-controls="collapseSurtidos" >
          {{ textMenuSurtidos }}
          </div>
        </div>
        <div id="collapseSurtidos" class="collapse" aria-labelledby="headingSurtidos" data-parent="#accordion" >
         <MenuItems v-bind:items="surtidos" />
        </div>
      </div>

     <div class="card">
        <div class="card-header card-header-aux font-2 color-4" id="headingEntrantes">
          <div class="collapsed" data-toggle="collapse" data-target="#collapseEntrantes" aria-expanded="false" aria-controls="collapseEntrantes" >
          {{ textMenuEntrantes }}
          </div>
        </div>
        <div id="collapseEntrantes" class="collapse" aria-labelledby="headingEntrantes" data-parent="#accordion" >
         <MenuItems v-bind:items="entrantes" />
        </div>
      </div>

     <div class="card">
        <div class="card-header card-header-aux font-2 color-4" id="headingArroces">
          <div class="collapsed" data-toggle="collapse" data-target="#collapseArroces" aria-expanded="false" aria-controls="collapseArroces" >
          {{ textMenuArroces }}
          </div>
        </div>
        <div id="collapseArroces" class="collapse" aria-labelledby="headingArroces" data-parent="#accordion" >
         <MenuItems v-bind:items="arroces" />
        </div>
      </div>

     <div class="card">
        <div class="card-header card-header-aux font-2 color-4" id="headingCarnes">
          <div class="collapsed" data-toggle="collapse" data-target="#collapseCarnes" aria-expanded="false" aria-controls="collapseCarnes" >
          {{ textMenuCarnes }}
          </div>
        </div>
        <div id="collapseCarnes" class="collapse" aria-labelledby="headingCarnes" data-parent="#accordion" >
         <MenuItems v-bind:items="carnes" />
        </div>
      </div>

     <div class="card">
        <div class="card-header card-header-aux font-2 color-4" id="headingPescados">
          <div class="collapsed" data-toggle="collapse" data-target="#collapsePescados" aria-expanded="false" aria-controls="collapsePescados" >
          {{ textMenuPescados }}
          </div>
        </div>
        <div id="collapsePescados" class="collapse" aria-labelledby="headingPescados" data-parent="#accordion" >
         <MenuItems v-bind:items="pescados" />
        </div>
      </div>

     <div class="card">
        <div class="card-header card-header-aux font-2 color-4" id="headingPescados">
          <div class="collapsed" data-toggle="collapse" data-target="#collapseBurger" aria-expanded="false" aria-controls="collapseBurger" >
          {{ textMenuBurger }}
          </div>
        </div>
        <div id="collapseBurger" class="collapse" aria-labelledby="headingPescados" data-parent="#accordion" >
         <MenuItems v-bind:items="burgers" />
        </div>
      </div>

     <div class="card">
        <div class="card-header card-header-aux font-2 color-4" id="headingPescados">
          <div class="collapsed" data-toggle="collapse" data-target="#collapsePasta" aria-expanded="false" aria-controls="collapsePasta" >
          {{ textMenuPasta }}
          </div>
        </div>
        <div id="collapsePasta" class="collapse" aria-labelledby="headingPescados" data-parent="#accordion" >
         <MenuItems v-bind:items="pasta" />
        </div>
      </div>

     <div class="card">
        <div class="card-header card-header-aux font-2 color-4" id="headingPostres">
          <div class="collapsed" data-toggle="collapse" data-target="#collapsePostres" aria-expanded="false" aria-controls="collapsePostres" >
          {{ textMenuPostres }}
          </div>
        </div>
        <div id="collapsePostres" class="collapse" aria-labelledby="headingPostres" data-parent="#accordion" >
         <MenuItems v-bind:items="postres" />
        </div>
      </div>

     <div class="card">
        <div class="card-header card-header-aux font-2 color-4" id="headingWine">
          <div class="collapsed" data-toggle="collapse" data-target="#collapseWine" aria-expanded="false" aria-controls="collapseWine" >
          {{ textMenuWine }}
          </div>
        </div>
        <div id="collapseWine" class="collapse" aria-labelledby="headingWine" data-parent="#accordion" >
         <MenuWine/>
        </div>
      </div>

      <div class="card">
        <div class="card-header card-header-aux font-2 color-4" id="headingGroup">
          <div class="collapsed" data-toggle="collapse" data-target="#collapseGroup" aria-expanded="false" aria-controls="collapseGroup" >
          {{ textGroup }}
          </div>
        </div>
        <div id="collapseGroup" class="collapse" aria-labelledby="headingGroup" data-parent="#accordion" >
          <MenuForGroup />
        </div>
      </div>

      <div class="card">
        <div class="card-header card-header-aux font-2 color-4" id="headingCalcots">
          <div class="collapsed" data-toggle="collapse" data-target="#collapseCalcots" aria-expanded="false" aria-controls="collapseCalcots" >
          {{ textMenuCalcots }}
          </div>
        </div>
        <div id="collapseCalcots" class="collapse" aria-labelledby="headingCalcots" data-parent="#accordion" >
          <MenuCalcots />
        </div>
      </div>
    </div>

    <hr class="container menu-separator">

    <div id="accordion2" class="container">
      <div class="card">
        <div class="card-header card-header-aux font-2 color-4" id="headingOne">
          <div class="collapsed" data-toggle="collapse" data-target="#collapseDaily" aria-expanded="false" aria-controls="collapseOne" >
            {{ textMenuOfTheDay }}
          </div>
        </div>
        <div id="collapseDaily" class="collapse" aria-labelledby="headingOne" data-parent="#accordion2" >
          <div class="card-body">
            <div class="container font-3 text-menu-of-the-day">
            {{ contentMenuOfTheDay }}
            </div>
            <div class="text-center p-3">
              <a target="_blank" class="social-media-logo-link" href="https://www.instagram.com/restaurantecasamuyo/" >
                <img class="social-media-logo" :src="require(`../../assets/img/social-media-logos/instagram_64.png`)"/>
              </a>
            </div>
          </div>
        </div>
      </div>


      <div class="card">
        <div class="card-header card-header-aux font-2 color-4" id="headingBreakfast">
          <div class="collapsed" data-toggle="collapse" data-target="#collapseBreakfast" aria-expanded="false" aria-controls="collapseOne" >
            {{ titleBreakfast }}
          </div>
        </div>
        <div id="collapseBreakfast" class="collapse" aria-labelledby="headingBreakfast" data-parent="#accordion2" >
          <div class="card-body">
            <div class="container font-3 text-menu-of-the-day">
              {{ contentBreakfast }}
            </div>
            <div class="text-center p-3">
              <a target="_blank" href="https://cartadigital.barmanagerapp.com/site/index/show/RESTAURANTECASAMUYO">
               <h4> <i class="fas fa-book-open color-4"></i> </h4>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuCalcots from "./MenuCalcots";
import MenuItems from "./MenuItems";
import MenuWine from "./MenuWine.vue";
import MenuForGroup from "./MenuForGroup"

export default {
  name: "MenuCard",
  components: {
    MenuForGroup,
    MenuItems,
    MenuCalcots,
    MenuWine
  },
  computed: {
    title: function() {
      return this.getGroupMenuData().title;
    },
    subtitle: function() {
      return this.getGroupMenuData().subtitle;
    },
    groupMenus: function() {
      return this.getGroupMenuData().groupMenu;
    },

    textGroup: function() {
      return this.getGroupMenuData().textGroup;
    },
    textMenuOfTheDay: function() {
      return this.getGroupMenuData().textMenuOfTheDay;
    },
    textMenuWine: function() {
      return this.getGroupMenuData().textMenuWine;
    },
    textMenuCalcots: function() {
      return this.getGroupMenuData().textMenuCalcots;
    },
    contentMenuOfTheDay: function() {
      return this.getGroupMenuData().contentMenuOfTheDay;
    },
    titleBreakfast: function() {
      return this.getGroupMenuData().titleBreakfast;
    },
    contentBreakfast: function() {
      return this.getGroupMenuData().contentBreakfast;
    },
    seeAllMenuText: function() {
      return this.getGroupMenuData().seeAllMenuText;
    },
    seeWineMenuText: function() {
      return this.getGroupMenuData().seeWineMenuText;
    },
    tapas: function() {
      return this.getGroupMenuData().tapas
    },
    surtidos: function() {
      return this.getGroupMenuData().surtidos
    },
    entrantes: function() {
      return this.getGroupMenuData().entrantes
    },
    arroces: function() {
      return this.getGroupMenuData().arroces
    },
    burgers: function() {
      return this.getGroupMenuData().burgers
    },
    pasta: function() {
      return this.getGroupMenuData().pasta
    },
    pescados: function() {
      return this.getGroupMenuData().pescados
    },
    carnes: function() {
      return this.getGroupMenuData().carnes
    },
    postres: function() {
      return this.getGroupMenuData().postres
    },
    textMenuTapas: function() {
      return this.getGroupMenuData().textTapas
    },
    textMenuSurtidos: function() {
      return this.getGroupMenuData().textSurtidos
    },
    textMenuEntrantes: function() {
      return this.getGroupMenuData().textEntrantes
    },
    textMenuArroces: function() {
      return this.getGroupMenuData().textArroces
    },
    textMenuCarnes: function() {
      return this.getGroupMenuData().textCarnes
    },
    textMenuPescados: function() {
      return this.getGroupMenuData().textPescados
    },
    textMenuPostres: function() {
      return this.getGroupMenuData().textPostres
    },
    textMenuPasta: function() {
      return this.getGroupMenuData().textPasta
    },
    textMenuBurger: function() {
      return this.getGroupMenuData().textHamburgesa
    },
  },
  methods: {
    getGroupMenuData: function() {
      return this.$store.getters.currentData.groupMenuSection;
    },
  },
};
</script>

<style scoped>
.text-menu-of-the-day {
  font-size: 18px;
}

.group-menu-section {
  margin-bottom: 40px;
}

.menu-header-section {
  background-image: url("../../assets/img/paella-rice.jpeg");
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
}

.menu-header-overlay {
  padding: 45px;
  background-color: rgba(0, 0, 0, 0.55);
}

.card-header-aux {
  padding-top: 16px;
  padding-bottom: 16px;
  cursor: pointer;
  font-size: 23px;
  letter-spacing: 1px;
}

.card-header-aux:hover {
  color: #ebf38b;
  background-color: #a42e0e;
  opacity: 0.8;
}

.see-all-link {
  text-decoration: none !important;
  color: #ebf38b;
}

.see-all-link:hover {
  border-bottom: solid 1px #ebf38b;
}

</style>
