<template>
  <div id="link-our-location-section" class="text-center container">
    <div class="row">
      <div class="col">
        <p class="module-title font-1 color-4">
          {{ sectionTitle }}
        </p>
      </div>
    </div>
    <div class="row">
      <div class="map-container col-12">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2992.9148109163543!2d2.172446115719795!3d41.397656379263246!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a4a312da96addf%3A0x57a680f35b375321!2sCasa%20Muyo!5e0!3m2!1sen!2sus!4v1619631349571!5m2!1sen!2sus"
          frameborder="0"
          style="border:0;"
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Location",
  data: function() {
    return {};
  },
  computed: {
    sectionTitle: function() {
      return this.getLocationData().title;
    },
  },
  methods: {
    getLocationData: function() {
      return this.$store.getters.currentData.location;
    },
  },
};
</script>

<style scoped>
#link-our-location-section {
  margin-top: 50px;
}

.map-container {
  padding: 10px;
  margin-top: 30px;
}

.map-container > iframe {
  width: 100%;
  height: 400px;
}
</style>
