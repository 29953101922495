<template>
  <section class="container">
    <div class="gallery">
      <img
        v-for="image in imagesInfo"
        :src="image.path"
        :alt="image.alt"
        :key="image.imageId"
        :class="image.imageClass"
        @click="updateCurrentImageInfo(image.path, image.imageClass)"
        data-toggle="modal"
        data-target="#galleryModalCenter"
      />
    </div>
    <GalleryModal v-if="isMobile">
      <img :src="currentImagePath" alt="" :class="currentImgClass" />
    </GalleryModal>
  </section>
</template>


<script>
import GalleryModal from "../GalleryModal/index";

export default {
  name: "Gallery",
  components: { GalleryModal },
  data: function () {
    return {
      imagesInfo: [
        {
          imageId: 6,
          imageClass: "img6",
          path: require("../../assets/img/gallery/6.jpg"),
        },
        {
          imageId: 1,
          imageClass: "img1",
          path: require("../../assets/img/gallery/1.jpg"),
        },
        {
          imageId: 2,
          imageClass: "img2",
          path: require("../../assets/img/gallery/2.jpg"),
        },
        {
          imageId: 3,
          imageClass: "img3",
          path: require("../../assets/img/gallery/3.jpg"),
        },
        {
          imageId: 4,
          imageClass: "img4",
          path: require("../../assets/img/gallery/4.jpg"),
        },
        {
          imageId: 5,
          imageClass: "img5",
          path: require("../../assets/img/gallery/5.jpg"),
        },
      ],
      currentImagePath: "",
      currentImgClass: ""
    };
  },
  computed: {
    isMobile: function () {
      return window.innerWidth <= 800;
    },
  },
  methods: {
    updateCurrentImageInfo: function (path, currentClass) {
      this.currentImagePath = path;
      this.currentImgClass = currentClass;
    },
  },
};
</script>

<style scoped lang="scss">
.gallery {
  margin: 3rem 0;
  height: 65vw;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: repeat(4, minmax(0, 1fr));
  column-gap: 1rem;
  row-gap: 1rem;

  .img1 {
    object-fit: cover;
  }

  .img6 {
    grid-row: span 2;
    grid-column: span 2;
  }
  .img4 {
    grid-row: span 2;
  }
  .img3,
  .img5 {
    grid-row: span 2;
  }
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: 1.5s cubic-bezier(0.13, 1, 0.32, 1);
  @media screen and (min-width: 800px) {
    &:hover {
      transform: scale(1.08);
    }
  }
}

@media only screen and (max-width: 800px) {
  .gallery {
    margin: 1rem 0;
    height: 90vw;
    column-gap: 0.4rem;
    row-gap: 0.4rem;
    .img6 {
      grid-column: span 2;
    }
    .img4 {
      grid-row: span 2;
    }
  }
}
</style>
