<template>
<div class="menu-item-card">
       <div v-for="menuItem in items" :key="menuItem" class="col-md-12 col-lg-12 menu-item">
            <div class="row">
                <div class="font-3 col-9 menu-item-name">
                    {{ menuItem.name }}
                 </div>
                <div class="col-3 menu-item-price">
                    {{ menuItem.price }}
                 </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "MenuItems",
  props: ["items"],
  methods: {
    getGroupMenuData: function() {
      return this.$store.getters.currentData.groupMenuSection;
    },
  },
};
</script>


<style>
.menu-item-card {
    padding-top: 9px;
    padding-bottom: 9px;
    padding-right: 9px;
    padding-left: 9px;
    height: 100%;
}

.menu-item-card .menu-item-price {
    font-weight: bold;
    text-align: right;
}

.menu-item-card .menu-item-name {
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
}

.menu-item-card .menu-item-description {
    font-size: 13px;
}


</style>
