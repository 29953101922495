<template>
<nav class="navbar navbar-dark navbar-expand-lg sticky-top">
    <div class="container">
        <a class="navbar-brand text-uppercase font-1" href="#">Restaurante Casamuyo</a>

        <button class="navbar-toggler" 
        ref="toggler"
        type="button" 
        data-toggle="collapse" 
        data-target="#navbarSupportedContent" 
        aria-controls="navbarSupportedContent" 
        aria-expanded="false" 
        aria-label="Toggle navigation"
        >
            <span class="navbar-toggler-icon"></span>
        </button>


        <div class="collapse navbar-collapse" id="navbarSupportedContent" ref="listnav">
            <ul class="navbar-nav mr-auto font-3">
                <li v-for="link in links" :key="link.link">
                    <a class="nav-link" @click="scrollTo(link.sectionId)"> {{ link.name }}</a>
                </li>

            </ul>
            <ul class="navbar-nav ml-auto border-top font-2">
                <li class="nav-item" :class="language ==='en' ? 'active' : ''">
                    <div class="nav-link language-button"  @click="currentLanguage('en')">ENG</div>
                </li>
                <li class="nav-item" :class="language === 'es' ? 'active' : ''">
                    <div class="nav-link language-button" @click="currentLanguage('es')">ESP</div>
                </li>
            </ul>
        </div>
    </div>
</nav>
</template>

<script>
export default {
    name: 'Header',
    data : function () {
        return {
            language : 'es'
        }
    },
    computed : {
        links : function () {
            return this.getNavBarItems();
        }
    },
    methods:{
        currentLanguage (lang) {
            this.language = lang
            this.$store.commit('currentLanguage', lang)
            if (this.$refs.listnav.classList.contains('show'))
                this.$refs.toggler.click();
        },
        getNavBarItems () {
            return this.$store.getters.currentData.navigationItems
        },
        scrollTo (seciontId) {
            document.querySelector(seciontId).scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
            if (this.$refs.listnav.classList.contains('show'))
                this.$refs.toggler.click();
        }
    }
}
</script>

<style>

.language-button {
    cursor: pointer;
}

.nav-link {
    cursor: pointer;
}

nav {
  background-color: #a42e0e;
  opacity: 0.90;
}

a.navbar-brand {
    font-size: 22px;
    color: #fb9a23 !important;
}

.scroll-to-top {
    position: fixed;
    color: #fb9a23;
    right: 20px;
    bottom: 20px;
    cursor: pointer;
    font-size: 45px;
}

.scroll-to-top:active, .scroll-to-top:hover {
    color: orangered;
}

</style>
