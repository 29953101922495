<template>
  <div id="link-about-us-sub-section" class="main-info-section">
    <div>
      <div class="row text-center pt-5 pb-4">
        <div class="col-12">
          <p class="module-title color-4">{{ infoTitle }}</p>
          <p class="module-subtitle color-1 text-uppercase">
            {{ infoSubTitle }}
          </p>
        </div>
      </div>
      <div class="container info-section">
        <div class="row">
          <div class="col-md-8 col-sm-12">
            <p class="info-description font-3">
              {{ infoContent }}
            </p>
          </div>
          <div class="col-md-4 image-section d-none d-md-block">
            <img
              class="logo"
              src="../../assets/img/logoRed.jpeg"
              alt="Card image cap"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GeneralInfo",
  data: function () {
    return {};
  },
  computed: {
    infoTitle: function () {
      return this.getInfoSection().title;
    },
    infoSubTitle: function () {
      return this.getInfoSection().subtitle;
    },
    infoContent: function () {
      return this.getInfoSection().content;
    },
    generalDescription: function () {
      return this.getDescriptionInfo();
    },
  },
  methods: {
    getInfoSection: function () {
      return this.$store.getters.currentData.infoSection;
    },
  },
};
</script>

<style lang="scss">
.info-section {
}

.logo {
  height: 180px;
}

.image-section {
  text-align: center;
}
.btn-outline-secondary.btn-sm {
  color: #173954;
}
.info-description {
  font-size: 16px;

  @media screen and (min-width: 960px) {
    font-size: 20px;
  }
}
</style>
