<template>
  <div
    id="menuForFourCarousel"
    class="carousel slide"
    data-ride="carousel"
    data-interval="false"
  >
    <div class="carousel-inner">
      <div
        class="carousel-item item"
        v-for="(groupMenu, index) in groupMenus"
        :class="index === 0 ? 'active' : ''"
        :key="groupMenu"
      >
      <div class="flexbox-container">
        <div class="image-box">
          <img class="hero-slide-image-section" :src="require(`../../assets/img/food-BOGAVANTE.jpg`)" alt="First slide" />
        </div>
        <div class="content-text text-center">
            <div class="p-4 inner-div">
              <div class="inner-menu">
                <div>
                  <h2 class="font-1 color-1">Menu {{ index + 1 }}</h2>
                </div>
                <div class="color-1 text-center menu-items">
                  <p class="font-3 border-bottom">{{ groupMenu.MESA }}</p>
                  <p class="font-3 border-bottom">{{ groupMenu.ENTARNTES }}</p>
                  <p class="font-3 border-bottom">{{ groupMenu.PRINCIPAL }}</p>
                  <p class="font-3 border-bottom">{{ groupMenu.POSTRE }}</p>
                  <p class="font-3 border-bottom">{{ groupMenu.BODEGA }}</p>
                </div>
                <div>
                  <h1 class="font-2 color-4">
                    <span class="price">{{ groupMenu.price }}€</span>
                  </h1>
                </div>
              </div>
            </div>
        </div>
      </div>
      </div>
    </div>
    <a
      class="carousel-control-prev"
      href="#menuForFourCarousel"
      role="button"
      data-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a
      class="carousel-control-next"
      href="#menuForFourCarousel"
      role="button"
      data-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</template>

<script>
export default {
  name: "MenuForFour",
  computed: {
    title: function() {
      return this.getGroupMenuData().title;
    },
    groupMenus: function() {
      return this.getGroupMenuData().groupMenu;
    },
  },
  methods: {
    getGroupMenuData: function() {
      return this.$store.getters.currentData.groupMenuSection;
    },
  },
};
</script>

<style scoped>

.flexbox-container {
  display: flex;
}

.image-box {
  height: 25rem;
}

.content-text {
  flex: 1;
}

.inner-div {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-slide-image-section {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 800px) {
  .flexbox-container {
    flex-direction: column;
    height: auto;
    align-items: center;
  }

  .hero-carousel-caption {
    padding-left: 0 !important;
  }
  
  .carousel-control-prev-icon {
  background-color: black;
  }

  .image-box {
  height: 15rem;
  width: 100%;
}
}
.carousel-control-next-icon {
  background-color: black;
}
</style>
