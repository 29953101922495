<template>
<div class="menu-item-card">
<div v-for="wine in wines" :key="wine">
	<p class="wine-type font-2 text-underline"> <u>{{ wine.type }}</u></p>
	<div class="row" v-for="product in wine.products" :key="product">
	<div class="font-3 col-sm-8 col-6 menu-item-name">
		{{ product.name }}
		</div>
	<div class="col-sm-2 col-3 menu-item-price">
		{{ product.priceGlass }}
		</div>
	<div class="col-sm-2 col-3 menu-item-price">
		{{ product.price }}
		</div>
	</div>

</div>
</div>
  
</template>

<script>
export default {
  name: "MenuWine",
  computed: {
   wines: function() {
      return this.getGroupMenuData().wines;
    },
  },
  methods: {
    getGroupMenuData: function() {
      return this.$store.getters.currentData.groupMenuSection;
    },
  },
};
</script>

<style>

.menu-item-card {
    padding-top: 9px;
    padding-bottom: 9px;
    padding-right: 9px;
    padding-left: 9px;
    height: 100%;
}

.menu-item-card .menu-item-price {
    font-weight: bold;
    text-align: right;
}

.menu-item-card .menu-item-name {
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
}

.wine-type {
	padding: 0px;
	margin-bottom: 0px;
	margin-top: 10px;
}

.menu-item-card .menu-item-description {
    font-size: 13px;
}

</style>