<template>
  <div
    id="carouselExampleControls"
    class="carousel slide"
    data-ride="carousel"
    data-interval="false"
  >
    <div class="carousel-inner">
      <div
        class="carousel-item item"
        v-for="(menuCalcot, index) in calcotsMenu"
        :class="index === 0 ? 'active' : ''"
        :key="menuCalcot"
      >
        <div class="flexbox-container m-3">
          <div class="image-box">
            <img class="hero-slide-image-section" :src="require(`../../assets/img/${menuCalcot.imagePath}`)" alt="First slide"/>
          </div>
          <div class="content-text text-center">
            <div class="p-4 inner-div">
              <div class="inner-menu">
                <div>
                  <h2 class="font-1 color-1"> {{ menuCalcot.title }}</h2>
                </div>
                <div class="color-1 menu-items">
                  <p v-for="item in menuCalcot.items" :key="item" class="font-3">{{ item }}</p>
                </div>
                <div>
                  <h6 class="font-3 color-4">
                    <span class="price">{{ menuCalcot.price }}</span>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a
      class="carousel-control-prev"
      href="#carouselExampleControls"
      role="button"
      data-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a
      class="carousel-control-next"
      href="#carouselExampleControls"
      role="button"
      data-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</template>

<script>
export default {
  name: "MenuCalcots",
  computed: {
    twoPersonMenus: function() {
      console.log(this.getGroupMenuData().MenuMas2Personas);
      return this.getGroupMenuData().MenuMas2Personas;
    },
    calcotsMenu: function() {
      return [this.getGroupMenuData().menuCalcots];
    },
  },
  methods: {
    getGroupMenuData: function() {
      return this.$store.getters.currentData.groupMenuSection;
    },
  },
};
</script>

<style scoped>
.flexbox-container {
  display: flex;
}

.image-box {
  height: 25rem;
}

.content-text {
  flex: 1;
}

.inner-div {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-slide-image-section {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 800px) {
  .flexbox-container {
    flex-direction: column;
    height: auto;
    align-items: center;
  }

  .hero-carousel-caption {
    padding-left: 0 !important;
  }
  
  .image-box {
    height: 15rem;
    width: 100%;
  }
}
</style>
