<template>
  <div
    class="modal fade"
    id="galleryModalCenter"
    tabindex="-1"
    role="dialog"
    aria-labelledby="galleryModalCenterTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span></span>
            <span></span>
          </button>
        </div>
        <div class="modal-body">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.modal-content {
  background: none !important;
  border: 0 !important;
}

.modal-header {
  border: 0 !important;
}

.close {
  opacity: 1;
  position: absolute;
  width: 20px;
  height: 25px;
  right: 2rem;
  margin: 0;
  padding: 0;
  span {
    background-color: white;
    display: block;
    width: 20px;
    height: 1px;
    transform: rotate(45deg);
    position: relative;
    + span {
      transform: rotate(-45deg);
      top: -1px;
    }
  }
}

.vue-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;

  &--inner {
    width: 40rem;
    // height: 10rem;
    margin: 12rem auto;
  }

  &--content {
    position: relative;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding: 1rem;
    background-clip: padding-box;
    border-radius: 0.2rem;
    height: 30rem;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.drop-in-enter-active,
.drop-in-leave-active {
  transition: all 0.3s ease-out;
}
.drop-in-enter-to,
.drop-in-leave-from {
  opacity: 0;
  transform: translateY(-500px);
}
</style>
