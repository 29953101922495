<template>
  <div id="link-hero-section">
    <div class="row banner-section align-items-center text-center">
      <div class="col">
        <div>
          <link href="https://awards.infcdn.net/2024/circ5_n.css" rel="stylesheet" />
          <div
            id="b-rcirc5"
            onclick="if(event.target.nodeName.toLowerCase() != 'a') {window.open(this.querySelector('.b-rcirc_bot_title').href);return 0;}"
            class="guru-image"
          >
            <div class="circ_cont">
              <img
                class="circ_img"
                src="https://awards.infcdn.net/img/star_red.svg"
                alt="star"
              /><a
                href="https://restaurantguru.com"
                target="_blank"
                class="b-rcirc_top_title"
                >Restaurant Guru 2024</a
              >
              <p class="b-rcirc_ttl">Recommended</p>
              <a
                href="https://restaurantguru.com/Casa-Muyo-Barcelona"
                class="b-rcirc_bot_title"
                target="_blank"
                >Casa Muyo</a
              >
            </div>
          </div>
        </div>
        <p class="font-4 color-5 banner-title">Restaurante CasaMuyo</p>
        <p class="font-3 color-5 banner-sub-title">FOUNDED IN 1964</p>
        <p class="font-4 color-3 banner-sub-title">Desayuno, Menu del dia, Carta</p>
        <div class="reservation-call-to-action">
          <div>
            <a href="tel:+34-932-31-77-16" class="reserve-button font-3"
              ><i class="fas fa-phone" aria-hidden="true"></i> {{ callUsText }}</a
            >
          </div>
          <div>
            <a
              target="_blank"
              href="https://wa.me/+34631810727"
              class="reserve-button font-3"
              ><i class="fab fa-whatsapp"></i> Whatsapp</a
            >
          </div>
          <div>
            <a
              target="_blank"
              href="mailto:casamuyorestaurante@gmail.com"
              class="reserve-button font-3"
              ><i class="fa fa-envelope"></i>{{ emailUsText }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeroImage",
  data: function () {
    return {};
  },
  components: {},
  computed: {
    callUsText: function () {
      return this.$store.getters.currentData.reservations.callUs;
    },
    emailUsText: function () {
      return this.$store.getters.currentData.reservations.emailUs;
    },
  },
};
</script>

<style>
.banner-section {
  height: 65vh;
}

.banner-title {
  text-shadow: 1px 1px 2px #ff0000;
  font-size: 35px;
  animation: moveLeft 2s ease-out;
}

.banner-sub-title {
  font-size: 20px;
  font-weight: bolder;
  letter-spacing: 0.2em;
  text-shadow: 1px 1px 2px #a42e0e;
  animation: moveRight 2s ease-out;
}

#link-hero-section {
  background-image: url("../../assets/img/casamuyowall.jpg");
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  padding-right: 20px;
  padding-left: 20px;
}

@keyframes moveLeft {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }

  80% {
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveRight {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }

  80% {
    transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    transform: translate(0px);
  }
}
@media screen and (min-width: 960px) {
  .banner-title {
    font-size: 43px;
  }
  .banner-sub-title {
    font-size: 23px;
  }
  .banner-section {
    height: 75vh;
  }
}

.reserve-button {
  margin: 10px;
  font-weight: bold;
  color: black;
  letter-spacing: 2px;
  background-color: orange;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  user-select: none;
  text-decoration: none !important;
  @media screen and (min-width: 960px) {
    padding: 0.5rem 1rem;
    border-radius: 0.3rem;
  }
}

.reservation-call-to-action {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.reservation-call-to-action > div {
  padding: 5px;
}

.reserve-button:hover {
  background-color: gold;
}
@keyframes pulse {
  0% {
      transform: scale(1);
  }

  50% {
      transform: scale(1.1);
  }

  100% {
      transform: scale(1);
  }
}
.guru-image {
  margin: auto;
  animation: pulse 1s infinite;
}
</style>
